<template>
  <div class="transaction-password flexbox vertical">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>修改交易密码</span>
      <span></span>
    </header>
    <main class="page-body flex-fill">
      <van-form @submit="onSubmit">
        <van-field
          v-model="password"
          type="password"
          placeholder="请输入交易密码"
          class="form-field"
          :rules="[{ required: true, message: '请输入交易密码' }]"
        />
        <van-field
          v-model="passwordAgain"
          type="password"
          class="form-field"
          placeholder="请再次输入"
          :rules="[
            { required: true, message: '请再次输入' },
            { validator: passwordValidator, message: '两次密码不一致' },
          ]"
        />
        <div class="flexbox align-center form-field">
          <van-field
            v-model="code"
            type="digit"
            clearable
            :maxlength="6"
            placeholder="请输入验证码"
            :rules="[{ required: true, message: '请输入验证码' }]"
          >
          </van-field>
          <van-button
            type="info"
            class="code-btn"
            :disabled="counting"
            @click="onSend"
          >
            <van-count-down
              :time="60000"
              v-if="counting"
              @finish="counting = false"
            >
              <template #default="timeData">
                <span class="block">重新获取({{ timeData.seconds }})</span>
              </template>
            </van-count-down>
            <span v-else>获取验证码</span>
          </van-button>
        </div>
        <div class="form-field flexbox align-center">
          <van-field
            v-model="captcha"
            clearable
            placeholder="请输入图形验证码"
            :rules="[{ required: true, message: '请输入图形验证码' }]"
          ></van-field>
          <img
            id="img-checkcode1"
            :src="imgCodeUrl"
            v-if="rerender"
            @click="onImgClick"
            width="95"
            height="35"
          />
        </div>
        <van-button
          class="btn-submit"
          round
          block
          type="info"
          native-type="submit"
        >
          提交
        </van-button>
      </van-form>
    </main>
  </div>
</template>
<script>
import { sendSMSCode } from '@/api/account.js';
import { BASE_URL } from '@/api/index.js';
import { modifyPaypwd } from '@/api/money.js';
export default {
  data() {
    return {
      password: '',
      passwordAgain: '',
      code: '',
      captcha: '',
      rerender: true,
      counting: false,
      imgCodeUrl: BASE_URL + '/customer/GetVerifyImg',
    };
  },
  methods: {
    onSend() {
      this.counting = true;
      sendSMSCode({ tel: this.$store.getters.userInfo.tel }).then(() => {
        this.$toast.success('发送成功');
      });
    },
    passwordValidator(value) {
      return value === this.password;
    },
    onImgClick() {
      this.rerender = false;
      this.$nextTick(() => {
        this.rerender = true;
      });
    },
    onSubmit() {
      modifyPaypwd({
        validateCode: this.code,
        newPwd: this.password,
        imgCode: this.captcha,
      }).then(res => {
        if (res.Result == true) {
          this.$toast.success(res.Info);
          setTimeout(() => {
            this.$router.go(-1);
          }, 1300);
        } else {
          this.$toast.fail(res.Info);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-body {
  padding: rem(60px);
  background-color: #fff;
}

.van-field {
  border-radius: rem(25px);
  background-color: #f7f7fc;
  font-size: 16px;
  padding-top: rem(24px);
  padding-bottom: rem(24px);
  overflow: visible;
}
.form-field {
  margin-bottom: rem(40px);
}
.van-count-down {
  color: #fff;
}
.code-btn {
  border-radius: rem(25px);
  margin-left: rem(20px);
  white-space: nowrap;
  color: #fff;
}
/deep/ .van-field__error-message {
  position: absolute;
  bottom: 0;
  transform: translateY(150%);
}
/deep/ .van-field--error .van-field__control {
  color: inherit;
}
/deep/ .van-field--error .van-field__control::placeholder {
  color: #ccc;
}
.btn-submit {
  margin-top: rem(180px);
}
</style>
